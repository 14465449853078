let url = "https://api.dev.bpl.artcloud.fi/api/";
let socketUrl = '';
let base_image_url = '';
const IS_PRODUCTION = window.location.hostname === "erp.betonipumppaus.fi";
if (IS_PRODUCTION) {
  url = "https://api.erp.betonipumppaus.fi/api/";
  socketUrl = 'https://api.erp.betonipumppaus.fi:8080';
  base_image_url = url.replace("api/", "public/");
} else if(window.location.hostname === "dev.bpl.artcloud.fi"){
  url = "https://api.dev.bpl.artcloud.fi/api/";
  socketUrl = 'https://api.dev.bpl.artcloud.fi:8081';
  base_image_url = url.replace("api/", "public/");
} else if(window.location.hostname === "hallinta.lampoliiga.fi"){
  url = "https://apihallinta.lampoliiga.fi/api/";
  socketUrl = 'https://apihallinta.lampoliiga.fi:8081';
  base_image_url = url.replace("api/", "public/");
} else if(window.location.hostname === "crm.artcloud.fi"){
  url = "https://api.crm.artcloud.fi/api/";
  socketUrl = 'https://api.crm.artcloud.fi:8082';
  base_image_url = url.replace("api/", "public/");
} else if(window.location.hostname === "localhost"){ 
  url = "https://api.dev.bpl.artcloud.fi/api/";
  socketUrl = 'https://api.dev.bpl.artcloud.fi:8081';
  base_image_url = url.replace("api/", "public/");
}
// } else if(window.location.hostname !== "dev.bpl.artcloud.fi" && !IS_PRODUCTION && window.location.hostname !== "localhost"){
//   url = 'https://94.237.105.222/api/api/';
//   socketUrl = 'https://94.237.105.222:8081';
//   base_image_url = url.replace("/api/api/", "/api/public/");
// }

//url = 'http://192.168.1.124:8000/api/'
// url = "https://api.erp.betonipumppaus.fi/api/"

export const BASE_URL = url;
export const BASE_IMG_URL = base_image_url;

const ENDPOINTS = {
  AUTH: {
    LOGIN: BASE_URL + "login",
    LOGOUT: BASE_URL + "logout",
    RESET_PASSWORD: BASE_URL + "password/email",
    RESET: BASE_URL + "password/reset",
  },
  USER: {
    ADD_USER: BASE_URL + "add-user",
    GET_USERS: BASE_URL + "user-list?",
    GET_ALL_USERS: BASE_URL + "users-name-list?",
    DELETE_USER: BASE_URL + "delete-user",
    GET_SINGLE_USER: BASE_URL + "getUser",
    EXPORT_USERS: BASE_URL + "export-users",
    IMPORT_USERS: BASE_URL + "import-users",
    JOB_DESCRIPTION: {
        ADD_JOB_DESCRIPTION: BASE_URL + "add-job-description",
        LIST_JOB_DESCRIPTION: BASE_URL + "job-description-list?",
        DELETE_JOB_DESCRIPTION: BASE_URL + "add-job-description",
      },
  },
  ROLE: {
    GET_ROLES: BASE_URL + "user-role-list",
  },
  PRODUCT: {
    ADD_PRODUCT: BASE_URL + "add-product",
    GET_ALL_PRODUCTS: BASE_URL + "product-list?",
    GET_SINGLE_PRODUCT: BASE_URL + "single-product?",
    DELETE_PRODUCT: BASE_URL + "delete-product?",
    GET_PRODUCT_STACK_DATA: BASE_URL + "get-product-stack",
    GET_PRODUCT_CODE: BASE_URL + "get-product-code",
    GET_PRODUCT_VARIANT_LIST: BASE_URL + "product-variant-price-list?",
    IMPORT_PRODUCT_STOCKS: BASE_URL + "import-products",
    EXPORT_PRODUCT_STOCKS: BASE_URL + "export-products",
    CHARACTERISTICS: {
      ADD_PRODUCT_CHARACTERISTICS_DATA: BASE_URL + "add-characteristic-data",
      LIST_PRODUCT_CHARACTERISTICS_DATA: BASE_URL + "add-characteristic-data",
      DELETE_PRODUCT_CHARACTERISTICS_DATA:
        BASE_URL + "delete-characteristic-data?",
      LINK_CHARACTERISTICS_TO_PRODUCT:
        BASE_URL + "add-product-characteristic-data",
      UNLINK_CHARACTERISTICS_TO_PRODUCT:
        BASE_URL + "delete-product-characteristic-data?",
      CHANGE_PRODUCT_CHARACTERISTICS_NAME:
        BASE_URL + "add-product-characteristic",
      DELETE_PRODUCT_CHARACTERISTIC:
        BASE_URL + "delete-product-characteristic?",
    },
    CATEGORIES: {
      GET_PRODUCT_CATEGORIES: BASE_URL + "product-category-list",
      ADD_PRODUCT_CATEGORY: BASE_URL + "add-product-category",
      EDIT_PRODUCT_CATEGORY: BASE_URL + "add-product-category",
      DELETE_PRODUCT_CATEGORY: BASE_URL + "delete-product-category?",
    },
     AGES: {
      GET_PRODUCT_AGES: BASE_URL + "product-category-list",
      ADD_PRODUCT_AGE: BASE_URL + "add-product-age",
      EDIT_PRODUCT_AGE: BASE_URL + "add-product-age",
      DELETE_PRODUCT_AGE: BASE_URL + "delete-product-age?",
    },
    BRANDS: {
      GET_BRANDS: BASE_URL + "product-brand-list",
      ADD_BRAND: BASE_URL + "add-product-brand",
      EDIT_BRAND: BASE_URL + "add-product-brand",
      DELETE_BRAND: BASE_URL + "delete-product-brand?",
    },
    TAX_RATES: {
      GET_TAXS: BASE_URL + "product-tax-list",
      ADD_TAX: BASE_URL + "add-product-tax",
      EDIT_TAX: BASE_URL + "add-product-tax",
      DELETE_TAX: BASE_URL + "delete-product-tax?",
    },
    WAREHOUSE: {
      ADD_NEW_WAREHOUSE: BASE_URL + "warehouse",
      ADD_WAREHOUSE: BASE_URL + "add-product-stock",
      DELETE_WAREHOUSE: BASE_URL + "delete-product-stock?",
    },
    BASIC_PRICES: {
      UPDATE_INFORMATION_BASIC_PRICE: BASE_URL + "add-product-basic-price",
      DELETE_BASIC_PRICE: BASE_URL + "delete-product-basic-price?",
    },
    DAILY_PRICES: {
      ADD_DAILY_PRICE: BASE_URL + "add-product-daily-price",
      DELETE_DAILY_PRICE: BASE_URL + "delete-product-daily-price?",
    },
    VARIANT_PRICES: {
      UPDATE_INFORMATION_VARIANT_PRICE:
        BASE_URL + "product-variant-price-update",
      DELETE_VARIANT_PRICE: BASE_URL + "product-variant-price-delete?",
    },
    LINKED_PRODUCTS: {
      ADD_LINKED_PRODUCT: BASE_URL + "add-product-linked-product",
      REMOVE_LINKED_PRODUCT: BASE_URL + "delete-product-linked-product",
      GET_LINKED_PRODUCTS: BASE_URL + "get-linked-products?"
    },
    SUPPLIERS: {
      LINK_NEW_SUPPLIER: BASE_URL + "link-product-supplier",
      UNLINK_NEW_SUPPLIER: BASE_URL + "unlink-product-supplier",
      CHECK_AND_UNCHECK_FACTORIES:
        BASE_URL + "update-product-factory-availability",
    },
    UNITS: {
      LINK_NEW_UNIT: BASE_URL + "link-product-unit",
      UNLINK_NEW_UNIT: BASE_URL + "unlink-product-unit",
      GET_UNITS: BASE_URL + "product-unit-list",
      ADD_UNIT: BASE_URL + "add-product-unit",
      EDIT_UNIT: BASE_URL + "add-product-unit",
      DELETE_UNIT: BASE_URL + "delete-product-unit?",
    },
  },
  SUPPLIERS: {
    GET_SUPPLIERS: BASE_URL + "supplier-list?",
    ADD_NEW_SUPPLIER: BASE_URL + "add-supplier",
    DELETE_SUPPLIER: BASE_URL + "delete-supplier?",
    SINGLE_SUPPLIER: BASE_URL + "get-supplier-by-id?",
    GET_SUPPLIER_STACK: BASE_URL + "get-supplier-stack",
    SUPPLIER_STATUS: {
      ADD_SUPPLIER_STATUS: BASE_URL + "add-supplier-status",
      SUPPLIER_STATUS_list: BASE_URL + "supplier-status-list",
      DELETE_SUPPLIER_STATUS: BASE_URL + "delete-supplier-status?",
    },
  },
  FILE: {
    ADD_FILE: BASE_URL + "add-file",
    ADD_MOB_FILE: BASE_URL + "upload-file-from-web",
    GET_FILES: BASE_URL + "file-list?",
    DELETE_FILE: BASE_URL + "delete-file?",
    GET_SINGLE_FILE: BASE_URL + "get-file-by-id?",
    UPLOAD_FILE: BASE_URL + "upload-file",
    EXPORT_FILE: BASE_URL + "export-files",
    IMPORT_FILE: BASE_URL + "import-files",
  },
  FILE_CATEGORY: {
    FILE_CATEGORY_LIST: BASE_URL + "file-category-list",
    ADD_FILE_CATEGORY: BASE_URL + "add-file-category",
    DELETE_FILE_CATEGORY: BASE_URL + "delete-file-category?",
  },
  NOTES: {
    LIST_NOTES: BASE_URL + "note-list?",
    DELETE_NOTE: BASE_URL + "delete-note?",
    GET_NOTES_CATEGORIES: BASE_URL + "note-category-list",
    ADD_NOTE_CATEGORY: BASE_URL + "add-note-category",
    DELETE_NOTE_CATEGORY: BASE_URL + "delete-note-category?",
    GET_NOTES_TYPES: BASE_URL + "note-type-list",
    ADD_NOTE: BASE_URL + "add-note",
    ADD_DELIVERY_NOTE: BASE_URL + "api-notes",
    MARK_AS_READ: BASE_URL + "view-all-message?",
    GET_SINGLE_NOTE: BASE_URL + "get-single-note?",
    REPLY: {
      ADD_REPLY: BASE_URL + "add-note-reply",
      DELETE_REPLY: BASE_URL + "delete-note-reply?",
    },
  },
  CUSTOM_FIELDS: {
    ADD_CUSTOM_FIELD: BASE_URL + "add-custom-field",
    ADD_CUSTOM_FIELDS_DATA: BASE_URL + "add-custom-field-data",
    DELETE_CUSTOM_FIELD: BASE_URL + "delete-custom-field?",
    REARRANGE_CUSTOM_FIELDS: BASE_URL + "re-arrange-order?",
    GET_GLOBAL_FIELDS: BASE_URL + "get-custom-fields-panel?",
  },
  STOCK: {
    LIST_STOCK: BASE_URL + "stock-list?",
    ADD_VEHICLE: BASE_URL + "add-stock",
    SINGLE_VEHICLE: BASE_URL + "single-stock?",
    DELETE_VEHICLE: BASE_URL + "delete-stock?",
    GET_STOCK_STACK: BASE_URL + "get-stock-stack",
    UPDATE_FILE_NAME_FROM_INSTRUCTIONS: BASE_URL + "update-file-name",
    STOCK_CATEGORY: {
      GET_STOCK_CATEGORIES: BASE_URL + "stock-category-list",
      ADD_STOCK_CATEGORY: BASE_URL + "add-stock-category",
      EDIT_STOCK_CATEGORY: BASE_URL + "add-stock-category",
      DELETE_STOCK_CATEGORY: BASE_URL + "delete-stock-category?",
    },
    SUPPLIERS: {
      GET_SUPPLIER_LIST: BASE_URL + "supplier-list",
    },
    STOCK_TYPE: {
      STOCK_TYPE_LIST: BASE_URL + "stock-type-list",
      ADD_STOCK_TYPE_LIST: BASE_URL + "add-stock-type",
      EDIT_STOCK_TYPE_LIST: BASE_URL + "add-stock-type",
      DELETE_STOCK_TYPE_LIST: BASE_URL + "delete-stock-type?",
    },
    STOCK_AREA: {
      STOCK_AREA_LIST: BASE_URL + "stock-type-list",
      ADD_STOCK_AREA_LIST: BASE_URL + "add-vehicle-area",
      EDIT_STOCK_AREA_LIST: BASE_URL + "add-vehicle-area",
      DELETE_STOCK_AREA_LIST: BASE_URL + "add-vehicle-area",
    },
    STOCK_STATUS: {
      GET_STOCK_STATUS: BASE_URL + "stock-status-list",
    },
    IMPORT_STOCKS: BASE_URL + "import-stocks",
    EXPORT_STOCKS: BASE_URL + "export-stocks",
  },
  FACTORY: {
    ADD_AND_EDIT_FACTORY: BASE_URL + "add-supplier-factory",
    DELETE_FACTORY: BASE_URL + "delete-supplier-factory?",
    GET_SINGLE_FACTORY: BASE_URL + "get-supplier-factory?",
    GET_FACTORY_LIST: BASE_URL + "supplier-fact-list?",
  },
  CONTACT_PERSONS: {
    ADD_AND_EDIT_CONTACT_PERSON: BASE_URL + "add-supplier-contact",
    DELETE_CONTACT_PERSON: BASE_URL + "delete-supplier-contact?",
  },
  SETTINGS: {
    SMTP: {
      GET_SMTP_SETTINGS: BASE_URL + "get-smtp",
      UPDATE_SMTP_SETTINGS: BASE_URL + "set-smtp",
    },
    COMPANY_INFO: {
      GET_COMPANY_INFO_SETTINGS: BASE_URL + "companyInfo",
      UPDATE_COMPANY_INFO_SETTINGS: BASE_URL + "companyInfo",
    },
    CUSTOM_FIELDS: {
      GET_ALL_CUSTOM_FIELDS: BASE_URL + "custom-field-list?",
      GET_CUSTOM_FIELDS_DATA_LIST: BASE_URL + "custom-field-data-list?",
    },
  },
  SOCKET: {
    CONNECT_NOTE_SOCKET: socketUrl,
  },
  DATA_TABLE: {
    USER_COLUMN_ORDER_LIST: BASE_URL + "user-column-order-list?",
    ADD_USER_COLUMN_ORDER: BASE_URL + "add-user-column-order",
    UPDATE_VISIBLE_RECORDS: BASE_URL + "add-pagination",
  },
  CUSTOM_STATIC_FIELDS_FORM_ORDER: {
    UPDATE_USER_FORM_ORDER: BASE_URL + "add-user-form-order",
    GET_USER_FORM_ORDER: BASE_URL + "get-user-form-order",
  },
  NOTIFICATIONS: {
    ADD_NOTIFICATION: BASE_URL + "add-notification",
    GET_NOTIFICATIONS: BASE_URL + "notification-list?",
    READ_ALL_NOTIFICATIONS: BASE_URL + "read-all-notification?",
  },
  CRONJOBS: {
    ADD_CRON: BASE_URL + "cronjobs",
    UPDATE_CRON: BASE_URL + "cronjobs/",
    CRONJOB_LIST: BASE_URL + "cronjobs",
    DELETE_CRONJOB: BASE_URL + "cronjobs/",
    UPDATE_ACTIVE_AND_INACTIVE: BASE_URL + "updateactive",
  },
  CUSTOMERS: {
    GET_CUSTOMER_STACK_DATA: BASE_URL + "get-customer-stack",
    ADD_NEW_CUSTOMER: BASE_URL + "add-customer",
    CUSTOMER_LIST: BASE_URL + "customer-list?",
    DELETE_SINGLE_CUSTOMER: BASE_URL + "delete-customer?",
    DELETE_CUSTOMER_DATA: BASE_URL + "delete-customer-data?",
    GET_SINGLE_CUSTOMER: BASE_URL + "get-customer-by-id?",
    CREDIT_INFORMATION_BY_USER: BASE_URL + "get-credit-score?",
    GET_CUSTOMER_ARE: BASE_URL + "get-customer-area",
    BASIC_INFORMATION: {
      ADD_BASIC_INFORMATION: BASE_URL + "add-customer-basic",
    },
    BILLING_INFORMATION: {
      ADD_BILLING_INFORMATION: BASE_URL + "add-customer-bill",
    },
    CONTACT_PERSONS: {
      ADD_CUSTOMER_CONTACT_PERSONS: BASE_URL + "add-customer-contact-person",
      CUSTOMER_CONTACT_PERSONS_LIST: BASE_URL + "customer-contact-person-list?",
      DELETE_CUSTOMER_CONTACT_PERSON:
        BASE_URL + "delete-customer-contact-person?",
      CUSTOMER_STATUS: {
        ADD_CUSTOMER_STATUS: BASE_URL + "add-customer-contact-status",
        GET_CUSTOMER_STATUS: BASE_URL + "get-customer-contact-status?",
        DELETE_CUSTOMER_STATUS: BASE_URL + "delete-customer-contact-status?",
      },
      CUSTOMER_TYPE: {
        ADD_CUSTOMER_TYPE: BASE_URL + "add-customer-contact-type",
        GET_CUSTOMER_TYPE: BASE_URL + "get-customer-contact-type?",
        DELETE_CUSTOMER_TYPE: BASE_URL + "delete-customer-contact-type?",
      },
      CUSTOMER_ROLE: {
        ADD_CUSTOMER_ROLE: BASE_URL + "add-customer-contact-role",
        GET_CUSTOMER_ROLE: BASE_URL + "customer-contact-role-list?",
        DELETE_CUSTOMER_ROLE: BASE_URL + "delete-customer-contact-role?",
      },
    },
    CONTRACT_PRICES: {
      ADD_CONTRACT_PRICE: BASE_URL + "add-customer-contract-price",
      GET_CONTRACT_PRICES: BASE_URL + "customer-contract-price-list?",
      DELETE_CONTRACT_PRICE: BASE_URL + "delete-customer-contract-price?",
    },
    CREDIT_INFORMATION: {
      DNB_ENDPOINT: "https://api-test.bisnode.fi/company/info/v2/rating/",
      SAVE_CREDIT_INFORMATION: BASE_URL + "update-credit-information",
    },
  },
  OFFERS: {
    LIST_OFFERS: BASE_URL + "offer-list?",
    DELETE_OFFER: BASE_URL + "delete-offer?",
    GET_OFFER_BY_ID: BASE_URL + "get-offer?",
    GET_OFFER_BY_NUMBER: BASE_URL + "get-offer-by-number?",
    GET_OFFER_STACK_DATA: BASE_URL + "get-offer-stack",
    ADD_NEW_OFFER: BASE_URL + "add-offer",
    ADD_NEW_OFFER_FROM_ORDER: BASE_URL + "order-from-offer",
    SEND_EMAIL: BASE_URL + "send-email",
    ACCEPT_OFFER: BASE_URL + "accept-offer-by-number?",
    REJECT_OFFER: BASE_URL + "reject-offer-by-number?",
    COPY_OFFER_FILES: BASE_URL + 'copy-offer-files?',
    OFFER_INFORMATION: {
      ADD_CUSTOMER_OFFER_INFORMATION: BASE_URL + "add-customer-offer",
      OFFER_STATUS: {
        ADD_OFFER_STATUS: BASE_URL + "add-offer-status",
        LIST_OFFER_STATUS: BASE_URL + "offer-status-list?",
        DELETE_OFFER_STATUS: BASE_URL + "delete-offer-status?",
      },
    },
    OFFER_CONTENT: {
      SEARCH_PRODUCTS: BASE_URL + "product-list-by-csp?",
    },
    OFFER_ATTACHMENTS: {
      ADD_ATTACHMENT: BASE_URL + "upload-offer-file",
      DELETE_OFFER_ATTACHMENT: BASE_URL + "delete-offer-file?",
      RENAME_OFFER_ATTACHMENT: BASE_URL + "add-offer-file",
    },
    CUSTOMER_PROPERTIES: {
      ADD_PROPERTY: BASE_URL + "add-offer-customer-property",
      DELETE_PROPERTY: BASE_URL + "offer-customer-property-list?",
      DELETE_OFFER_CUSTOMER_PROPERTIES:
        BASE_URL + "delete-offer-customer-property?",
      CUSTOMER_PROPERTIES_CRUD: {
        ADD_CUSTOMER_PROPERTY_TYPE:
          BASE_URL + "add-offer-customer-property-type",
        DELETE_CUSTOMER_PROPERTY_TYPE:
          BASE_URL + "delete-offer-customer-property-type?",
      },
    },
  },
  ORDERS: {
    ADD_NEW_ORDER: BASE_URL + "add-order",
    ADD_NEW_INVOICE_FROM_ORDER: BASE_URL + "invoice/invoice-from-order",
    COPY_ORDER: BASE_URL + "copy-order-by-id",
    DELETE_ORDER: BASE_URL + "delete-order",
    LIST_ORDERS: BASE_URL + "order-list?",
    GET_ORDER_BY_ID: BASE_URL + "get-order-by-id?",
    GET_ORDER_STACK_DATA: BASE_URL + "get-order-stack",
    ORDER_INFORMATION: {
      ADD_CUSTOMER_ORDER_INFORMATION: BASE_URL + "add-order-info",
      ORDER_STATUS: {
        ADD_ORDER_STATUS: BASE_URL + "add-order-status",
        LIST_ORDER_STATUS: BASE_URL + "order-status-list?",
        DELETE_ORDER_STATUS: BASE_URL + "delete-order-status?",
      },
    },
    ORDER_DELIVERY: {
      ADD_ORDER_DELIVERY: BASE_URL + "add-order-delivery",
      DELETE_ORDER_DELIVERY: BASE_URL + "delete-order-delivery",
      ADD_ORDER_DELIVERY_PRDUCT: BASE_URL + "add-order-delivery-product",
      LIST_ORDER_DELIVERY: BASE_URL + "delivery-order-list",
      LIST_ORDER_DELIVERY_TIME: BASE_URL + "order-delivery-time-list?",
      LIST_ORDER_DELIVERY_DRIVERS: BASE_URL + "order-delivery-driver-list?",
      LIST_ORDER_DELIVERY_COVERINGS: BASE_URL + "order-delivery-cover-list?",
      LIST_ORDER_DISMANTLING_METHODS: BASE_URL + "unloading-methods?",
      LIST_ORDER_RETURN_FREIGHTS: BASE_URL + "fright-charges?",
      LIST_ORDER_WEIGHTS: BASE_URL + "washing-methods?", 
      UPLOAD_SIGNATURE: BASE_URL + "add-signature-file",
      ADD_UPDATE_NOTES: BASE_URL + "api-notes",
      ASSIGN_DELIVERY_TIMES: BASE_URL + "assign-delivery-times-data/",
      ASSIGN_DELIVERY_COVERS: BASE_URL + "assign-delivery-cover-data/",
      UPDATE_ADDITIVES_VALUE: BASE_URL + "add-order-delivery-linked-product-value",
      EDIT_ORDER_DELIVERY_PRODUCT: BASE_URL + "edit-order-delivery-product",
      UPDATE_CREW_REPORTAGE: BASE_URL + "update-crew-reportage",
      VEHICLE: {
        ADD_DELIVERY_VEHICLE: BASE_URL + "add-order-delivery-driver",
        DELETE_DELIVERY_VEHICLE: BASE_URL + "delete-order-delivery-vehicle",
        DELIVERY_START_MANUALLY: BASE_URL + "delivery-start-manually",
        PUMP_TRUCK_COVER_DATA: BASE_URL + "pump-truck-cover-data",
        CONCREATE_PUMP_TRUCK_COVER_DATA: BASE_URL + "concreate-pump-truck-cover-data",
        ASSIGN_FLOORMAN_COVER_DATA: BASE_URL + "assign-floorman-cover-data",
      },
      PRODUCT: {
        GET_DELIVERY_PRODUCTS: BASE_URL + "delivery-order-list",
      },
    },
    ORDER_ATTACHMENTS: {
      ADD_ATTACHMENT: BASE_URL + "upload-order-file",
      DELETE_ORDER_ATTACHMENT: BASE_URL + "delete-order-file?",
      RENAME_ORDER_ATTACHMENT: BASE_URL + "add-order-file",
    },
  },
  INVOICE: {
    ADD_NEW_INVOICE: BASE_URL + "add-new-invoice",
    GET_INVOICE_BY_ID: BASE_URL + "invoice/",
    DELETE_INVOICE: BASE_URL + "invoice",
    LIST_INVOICE: BASE_URL + "invoice?",
    GET_ORDER_STACK_DATA: BASE_URL + "get-order-stack",
    ADD_INVOICE_INFORMATION: BASE_URL + "invoice/add-invoice-info",
    INVOICE_STATUS: {
        ADD_INVOICE_STATUS: BASE_URL + "invoice-status",
        EDIT_INVOICE_STATUS: BASE_URL + "invoice-status/",
        LIST_INVOICE_STATUS: BASE_URL + "invoice-status?",
        DELETE_INVOICE_STATUS: BASE_URL + "invoice-status/",
    },
    INVOICE_TYPE: {
        ADD_INVOICE_TYPE: BASE_URL + "invoice-type",
        LIST_INVOICE_TYPE: BASE_URL + "invoice-type?",
        EDIT_INVOICE_TYPE: BASE_URL + "invoice-type/",
        DELETE_INVOICE_TYPE: BASE_URL + "invoice-type/",
    },
    PRODUCT_OPERATION: {
        LIST_PRODUCT_INVOICE_WITH_ORDER: BASE_URL + "invoice/getProducts/"
    },
    OPERATION: {
      STORE_INVOICE_DETAIL: BASE_URL + 'invoice/store-invoice-detail',
      ADD_INVOICE_INFO: BASE_URL + 'invoice/add-invoice-info',
      UPDATE_INVOICE_INFO: BASE_URL + 'invoice/',
      SEND_TO_NETVISOR: BASE_URL + 'invoice/sentNetVisor/',
    }
  },
  DELIVERY_CALENDAR: {
    GET_DAILY_VIEW: BASE_URL + "daily-delivery-data?",
    GET_WEEKLY_VIEW: BASE_URL + "weekly-delivery-data?",
    GET_CUSTOMER_ROOT_LIST: BASE_URL + "customer-root-list?",
    GET_OFFER_ROOT_LIST: BASE_URL + "offer-root-list?",
    GET_OFFER_STATUS_ROOT_LIST: BASE_URL + "offer-status-root-list?",
    GET_ORDER_STATUS_ROOT_LIST: BASE_URL + "get-order-status-root-list",
    GET_USER_ROOT_LIST: BASE_URL + "user-root-list?",
    GET_PRODUCT_CATEGORY_ROOT_LIST: BASE_URL + "product-category-root-list?",
    GET_VEHICLE_ROOT_LIST: BASE_URL + "vehicle-root-list?",
    GET_CALENDAR_ROOT_LIST: BASE_URL + "calender-root-list?"
  },
};

export default ENDPOINTS;


export const config = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("x-access-token"),
  },
};

export const configAuth = {
  headers: {
    // 'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
    Accept: "application/json",
  },
};

export const dnbConfig = {
  headers: {
    "x-api-key": "1234567890ABC",
  },
};
